import React, { useState } from "react";
import { Link } from "gatsby";
import Sidebar from "./Sidebar";
import PostSorter from "./PostSorter";
import ArchivePickerMonth from "./ArchivePickerMonth";
import SingleRowVertical from "./post-previews/SingleRowVertical";
import {
  PageTitle,
  PageDivider,
  MaxWidth,
  FlexRow,
  Col66,
  Col33,
  SmallDivider,
  SmallText,
  PickerSorterWrapper,
} from "./ui-components";

export default function ArchiveMonth({ posts, month, year, sidebarInfo }) {
  const [sortByNewest, setSortByNewest] = useState(true);

  const handleOnChange = (sortByNewest) => {
    setSortByNewest(sortByNewest);
  };

  const { allSanitySiteSettings, allSanityAuthor, tags } = sidebarInfo;

  const newTop = (
    <SingleRowVertical
      posts={posts}
      authorTop={true}
      readMoreLink={true}
      className={"border-bottom"}
    />
  );

  const oldTop = (
    <SingleRowVertical
      posts={posts.reverse()}
      authorTop={true}
      readMoreLink={true}
      className={"border-bottom"}
    />
  );

  <PostSorter sortByNewest={sortByNewest} handleOnChange={handleOnChange} />;

  return (
    <div>
      <MaxWidth>
        <PageTitle>
          {month} {year} Archive
        </PageTitle>
      </MaxWidth>
      <PageDivider />
      <MaxWidth>
        <FlexRow>
          <Col66>
            <p style={{ marginBottom: 0, color: "0,0,0,0.84" }}>
              All stories published by Inside Cal Poly in {month} of {year}.
            </p>

            <SmallDivider />

            <PickerSorterWrapper>
              <ArchivePickerMonth postsYear={year} postsMonth={month} />
              <PostSorter sortByNewest={sortByNewest} handleOnChange={handleOnChange} />
            </PickerSorterWrapper>

            <SmallDivider />

            {sortByNewest ? newTop : oldTop}

            <SmallText>
              These were the all of the stories published by Inside Cal Poly in {month} of {year};
              you can also browse other articles written by Inside Cal Poly by viewing the full{" "}
              <Link to={"/archive/"}>archive</Link>.
            </SmallText>
          </Col66>

          <Col33>
            <Sidebar
              settings={allSanitySiteSettings.nodes[0]}
              tags={tags}
              authors={allSanityAuthor.nodes}
            />
          </Col33>
        </FlexRow>
      </MaxWidth>
    </div>
  );
}
