import React from "react";
import { graphql } from "gatsby";
import ArchiveMonth from "../components/ArchiveMonth";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterUniqueTags } from "../lib/helpers";

export const query = graphql`
  query ArchiveMonthTemplateQuery($thisMonth: Date!, $nextMonth: Date!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: ASC }
      filter: { publishedAt: { gte: $thisMonth, lt: $nextMonth, ne: null } }
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMM DD, YYYY")
          month: publishedAt(formatString: "MMMM")
          year: publishedAt(formatString: "YYYY")
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              name
              id
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          tags {
            tagName
            slug {
              current
            }
          }
        }
      }
    }
    allSanitySiteSettings {
      nodes {
        contactInfo {
          email
          facebook
          instagram
          twitter
        }
        description
        title
      }
    }
    allSanityAuthor {
      nodes {
        image {
          asset {
            fixed(width: 40) {
              ...GatsbySanityImageFixed
            }
          }
        }
        id
        name
        slug {
          current
        }
      }
    }
  }
`;

export default function ArchiveMonthTemplate(props) {
  const { data, location } = props;
  const posts = data && data.posts && mapEdgesToNodes(data.posts);

  const year = posts[0].year;
  const month = posts[0].month;

  const allSanitySiteSettings = data && data.allSanitySiteSettings;
  const allSanityAuthor = data && data.allSanityAuthor;
  const tags = filterUniqueTags(posts);

  const sidebarInfo = { allSanitySiteSettings, allSanityAuthor, tags };

  return (
    <Layout location={location.pathname}>
      <SEO
        title={`${month} ${year} Archive` || "Untitled"}
        description={`Archive of stories published by Inside Cal Poly in ${month} of ${year}.`}
      />
      <ArchiveMonth posts={posts} sidebarInfo={sidebarInfo} year={year} month={month} />
    </Layout>
  );
}
